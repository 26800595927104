@import '~flowbite';

@tailwind components;
@tailwind utilities;

#root {
  @apply flex flex-col min-h-screen;
}
::-webkit-scrollbar {
  @apply h-2 w-2 rounded-full bg-gray-300 dark:bg-gray-900;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-500 rounded-full hover:bg-gray-400 dark:border-gray-900 dark:bg-gray-700 dark:hover:bg-gray-600;
}

/* FullCalendar CSS overrides */
.fc .fc-event {
  @apply cursor-pointer hover:opacity-80 transition-all;
}
.fc .fc-today-button,
.fc .fc-today-button:disabled {
  @apply opacity-100 cursor-pointer hidden sm:inline-block h-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded border-none;
}
.fc .fc-col-header {
  @apply dark:bg-gray-800;
}
.fc .fc-prev-button,
.fc .fc-next-button {
  @apply bg-gray-900 border-none h-10;
}
.fc .fc-toolbar-title {
  @apply text-xl font-bold md:text-2xl tracking-tight dark:text-white;
}
.fc .fc-daygrid-day-number,
.fc .fc-col-header-cell-cushion {
  @apply text-gray-700 dark:text-white;
}
